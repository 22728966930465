import * as React from "react"
import "../index.css"

import { Trans } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"

import { Box, Center, Flex, Grid, Image } from "@chakra-ui/react"
import { featuresPage, welaFeatures } from "../../utils/descriptions"
import Footer from "../Home/Footer"
import HomeNavBar from "../Home/HomeNavBar"
import { AiOutlineCheck } from "react-icons/ai"
import Ads from "../Home/Ads"

const FeaturePage = () => {
  return (
    <>
      <HomeNavBar />
      <Box
        w="100%"
        p={4}
        bgGradient="linear(to-l, #AD04FD, #0571C2)"
        color="white"
        position={"relative"}
        py={{ lg: "24", base: "12" }}
      >
        <Box zIndex={5} position={"relative"}>
          <Box
            fontSize={{ "2xl": 48, xl: 43, lg: 35, base: 25 }}
            fontWeight={600}
            textAlign={"center"}
          >
            <Trans>Wela Features</Trans>
          </Box>
          <Box
            textAlign={"center"}
            fontSize={{ "2xl": 17, xl: 15, lg: 14, sm: 14, base: 11 }}
            margin={{
              "2xl": "1rem 30rem 2rem 30rem",
              xl: "1rem 20rem 2rem 20rem",
              lg: "1rem 14rem 1rem 14rem",
              sm: "0.5rem 6rem 1rem 6rem",
              base: "0.5rem 1rem 1rem 1rem",
            }}
          >
            <Trans>
              Empower your school to perform at its best with integrated
              management, teaching, and learning tools. For K-12 to Colleges,
              Universities.
            </Trans>
          </Box>
        </Box>
        <Box
          mx={{ "2xl": "48", xl: "36", lg: "20", base: "1" }}
          mt={{ lg: "32", base: "20" }}
        >
          <Grid
            templateColumns={{
              lg: "repeat(5, 1fr)",
              sm: "repeat(3, 1fr)",
              base: "repeat(2, 1fr)",
            }}
            gap={{ "2xl": 18, xl: 8, lg: 10, sm: 7, base: 2 }}
          >
            {welaFeatures.map(feat => (
              <Box
                key={feat.title}
                textAlign="center"
                mt={feat.title === "Parents" ? "-0.5" : "0"}
              >
                <Box>
                  <Center>
                    <Image
                      src={feat.icon}
                      w={{
                        lg:
                          feat.title === "Students"
                            ? "90px"
                            : feat.title === "Parents"
                            ? "67px"
                            : "60px",
                        base: feat.title === "Students" ? "40px" : "30px",
                      }}
                      mt={
                        feat.title === "Students" || feat.title === "Parents"
                          ? "1.5"
                          : "0"
                      }
                    />
                  </Center>
                </Box>
                <Box fontSize={{ lg: 22, base: 18 }} fontWeight={600} py="2">
                  <Trans>{feat.title}</Trans>
                </Box>
                <Box
                  fontSize={14}
                  display={["none", "none", "none", "block", "block"]}
                >
                  <Trans>{feat.desc}</Trans>
                </Box>
              </Box>
            ))}
          </Grid>
        </Box>
      </Box>

      <Box
        mx={{ "2xl": "20rem", xl: "8rem", lg: "5rem", base: "1rem" }}
        mt={"32"}
      >
        <Grid
          templateColumns={{ lg: "repeat(2, 1fr)", base: "repeat(1, 1fr)" }}
          gap={{ "2xl": 25, xl: 18, lg: 10, sm: 15, base: 5 }}
        >
          <Box position={"relative"} mt={{ lg: "8", base: "0" }}>
            <Center>
              <Image
                src={featuresPage.featImg1}
                w={{ lg: "90%", sm: "60%" }}
                mb="0"
                ml={{ lg: "6", base: "0" }}
                alt="offlineImg"
              />
            </Center>
          </Box>
          <Box>
            <Box
              fontWeight={700}
              fontSize={{ "2xl": 40, xl: 35, lg: 25, base: 22 }}
            >
              <Trans>{featuresPage.featTitle1}</Trans>
            </Box>
            <Box
              w="100%"
              fontSize={{ lg: 18, base: 16 }}
              mt={{ xl: "5", base: "3" }}
              fontWeight={500}
            >
              <Trans>{featuresPage.featDesc1}</Trans>
            </Box>
            <Grid
              templateColumns={"repeat(2, 1fr)"}
              gap={{ "2xl": 25, xl: 20, lg: 10, sm: 15, base: 5 }}
              mt="6"
            >
              <Box>
                {featuresPage.featList1one.map(data => (
                  <Flex key={data} gap="2" py="1">
                    <AiOutlineCheck />
                    <Box fontSize={{ lg: 15, base: 14 }}>
                      <Trans>{data}</Trans>
                    </Box>
                  </Flex>
                ))}
              </Box>
              <Box>
                {featuresPage.featList1two.map(data => (
                  <Flex key={data} gap="2" py="1">
                    <AiOutlineCheck />
                    <Box fontSize={{ lg: 15, base: 14 }}>
                      <Trans>{data}</Trans>
                    </Box>
                  </Flex>
                ))}
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Box>
      <Box
        mx={{ "2xl": "20rem", xl: "8rem", lg: "5rem", base: "1rem" }}
        mt={"32"}
      >
        <Grid
          templateColumns={{ lg: "repeat(2, 1fr)", base: "repeat(1, 1fr)" }}
          gap={{ "2xl": 25, xl: 18, lg: 10, sm: 15, base: 5 }}
        >
          <Box
            position={"relative"}
            mt={{ lg: "8", base: "0" }}
            display={["block", "block", "block", "none", "none"]}
          >
            <Center>
              <Image
                src={featuresPage.featImg2}
                w={{ lg: "80%", sm: "60%" }}
                mb="0"
                alt="offlineImg"
              />
            </Center>
          </Box>
          <Box>
            <Box
              fontWeight={700}
              fontSize={{ "2xl": 40, xl: 35, lg: 25, base: 22 }}
            >
              <Trans>{featuresPage.featTitle2}</Trans>
            </Box>
            <Box
              w="100%"
              fontSize={{ lg: 18, base: 16 }}
              mt={{ xl: "5", base: "3" }}
              fontWeight={500}
            >
              <Trans>{featuresPage.featDesc2}</Trans>
            </Box>
            <Grid
              templateColumns={"repeat(2, 1fr)"}
              gap={{ "2xl": 25, xl: 20, lg: 10, sm: 15, base: 5 }}
              mt="6"
            >
              <Box>
                {featuresPage.featList2one.map(data => (
                  <Flex key={data} gap="2" py="1">
                    <AiOutlineCheck />
                    <Box fontSize={{ lg: 15, base: 14 }}>
                      <Trans>{data}</Trans>
                    </Box>
                  </Flex>
                ))}
              </Box>
              <Box>
                {featuresPage.featList2two.map(data => (
                  <Flex key={data} gap="2" py="1">
                    <AiOutlineCheck />
                    <Box fontSize={{ lg: 15, base: 14 }}>
                      <Trans>{data}</Trans>
                    </Box>
                  </Flex>
                ))}
              </Box>
            </Grid>
          </Box>
          <Box
            position={"relative"}
            mt="8"
            display={["none", "none", "none", "block", "block"]}
          >
            <Center>
              <Image
                src={featuresPage.featImg2}
                w={{ lg: "80%", sm: "60%" }}
                mb="0"
                mr={{ lg: "10", base: "0" }}
                alt="offlineImg"
              />
            </Center>
          </Box>
        </Grid>
      </Box>

      <Box
        mx={{ "2xl": "20rem", xl: "8rem", lg: "5rem", base: "1rem" }}
        mt={"32"}
      >
        <Grid
          templateColumns={{ lg: "repeat(2, 1fr)", base: "repeat(1, 1fr)" }}
          gap={{ "2xl": 25, xl: 18, lg: 10, sm: 15, base: 5 }}
        >
          <Box position={"relative"}>
            <Center>
              <Image
                src={featuresPage.featImg3}
                w={{ lg: "75%", sm: "60%" }}
                ml={{ lg: "8", base: "0" }}
                alt="offlineImg"
              />
            </Center>
          </Box>
          <Box>
            <Box
              fontWeight={700}
              fontSize={{ "2xl": 40, xl: 35, lg: 25, base: 22 }}
            >
              <Trans>{featuresPage.featTitle3}</Trans>
            </Box>
            <Box
              w="100%"
              fontSize={{ lg: 18, base: 16 }}
              mt={{ xl: "5", base: "3" }}
              fontWeight={500}
            >
              <Trans>{featuresPage.featDesc3}</Trans>
            </Box>
            <Grid
              templateColumns={"repeat(2, 1fr)"}
              gap={{ "2xl": 25, xl: 20, lg: 10, sm: 15, base: 5 }}
              mt="6"
            >
              <Box>
                {featuresPage.featList3one.map(data => (
                  <Flex key={data} gap="2" py="1">
                    <AiOutlineCheck />
                    <Box fontSize={{ lg: 15, base: 14 }}>
                      <Trans>{data}</Trans>
                    </Box>
                  </Flex>
                ))}
              </Box>
              <Box>
                {featuresPage.featList3two.map(data => (
                  <Flex key={data} gap="2" py="1">
                    <AiOutlineCheck />
                    <Box fontSize={{ lg: 15, base: 14 }}>
                      <Trans>{data}</Trans>
                    </Box>
                  </Flex>
                ))}
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Box>

      <Box
        mx={{ "2xl": "20rem", xl: "8rem", lg: "5rem", base: "1rem" }}
        mt={"32"}
      >
        <Grid
          templateColumns={{ lg: "repeat(2, 1fr)", base: "repeat(1, 1fr)" }}
          gap={{ "2xl": 25, xl: 18, lg: 10, sm: 15, base: 5 }}
        >
          <Box
            position={"relative"}
            mt={{ lg: "8", base: "-10" }}
            display={["block", "block", "block", "none", "none"]}
          >
            <Center>
              <Image
                src={featuresPage.featImg4}
                w={{ lg: "80%", sm: "60%" }}
                alt="offlineImg"
              />
            </Center>
          </Box>
          <Box>
            <Box
              fontWeight={700}
              fontSize={{ "2xl": 40, xl: 35, lg: 25, base: 22 }}
            >
              <Trans>{featuresPage.featTitle4}</Trans>
            </Box>
            <Box
              w="100%"
              fontSize={{ lg: 18, base: 16 }}
              mt={{ xl: "5", base: "3" }}
              fontWeight={500}
            >
              <Trans>{featuresPage.featDesc4}</Trans>
            </Box>
            <Grid
              templateColumns={"repeat(2, 1fr)"}
              gap={{ "2xl": 25, xl: 20, lg: 10, sm: 15, base: 5 }}
              mt="6"
            >
              <Box>
                {featuresPage.featList4one.map(data => (
                  <Flex key={data} gap="2" py="1">
                    <AiOutlineCheck />
                    <Box fontSize={{ lg: 15, base: 14 }}>
                      <Trans>{data}</Trans>
                    </Box>
                  </Flex>
                ))}
              </Box>
              <Box>
                {featuresPage.featList4two.map(data => (
                  <Flex key={data} gap="2" py="1">
                    <AiOutlineCheck />
                    <Box fontSize={{ lg: 15, base: 14 }}>
                      <Trans>{data}</Trans>
                    </Box>
                  </Flex>
                ))}
              </Box>
            </Grid>
          </Box>
          <Box
            position={"relative"}
            mt="-8"
            display={["none", "none", "none", "block", "block"]}
          >
            <Center>
              <Image
                src={featuresPage.featImg4}
                w={{ lg: "75%", sm: "60%" }}
                mb="0"
                mr={{ lg: "10", base: "0" }}
                alt="offlineImg"
              />
            </Center>
          </Box>
        </Grid>
      </Box>

      <Box
        mx={{ "2xl": "20rem", xl: "8rem", lg: "5rem", base: "1rem" }}
        mt={"32"}
      >
        <Grid
          templateColumns={{ lg: "repeat(2, 1fr)", base: "repeat(1, 1fr)" }}
          gap={{ "2xl": 25, xl: 18, lg: 10, sm: 15, base: 5 }}
        >
          <Box position={"relative"} mt={{ lg: "8", base: "0" }}>
            <Center>
              <Image
                src={featuresPage.featImg5}
                w={{ lg: "75%", sm: "60%" }}
                ml={{ lg: "6", base: "0" }}
                alt="offlineImg"
              />
            </Center>
          </Box>
          <Box>
            <Box
              fontWeight={700}
              fontSize={{ "2xl": 40, xl: 35, lg: 25, base: 22 }}
            >
              <Trans>{featuresPage.featTitle5}</Trans>
            </Box>
            <Box
              w="100%"
              fontSize={{ lg: 18, base: 16 }}
              mt={{ xl: "5", base: "3" }}
              fontWeight={500}
            >
              <Trans>{featuresPage.featDesc5}</Trans>
            </Box>
            <Grid
              templateColumns={"repeat(2, 1fr)"}
              gap={{ "2xl": 25, xl: 20, lg: 10, sm: 15, base: 5 }}
              mt="6"
            >
              <Box>
                {featuresPage.featList5one.map(data => (
                  <Flex key={data} gap="2" py="1">
                    <AiOutlineCheck />
                    <Box fontSize={{ lg: 15, base: 14 }}>
                      <Trans>{data}</Trans>
                    </Box>
                  </Flex>
                ))}
              </Box>
              <Box>
                {featuresPage.featList5two.map(data => (
                  <Flex key={data} gap="2" py="1">
                    <AiOutlineCheck />
                    <Box fontSize={{ lg: 15, base: 14 }}>
                      <Trans>{data}</Trans>
                    </Box>
                  </Flex>
                ))}
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Box>

      <Ads />

      {/* <WantMoreInWela /> */}
      <Footer />
    </>
  )
}

export default FeaturePage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
